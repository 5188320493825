import axios from 'axios';

async function getPermissions(token) {
    try {
        const response = await axios.get('https://api.knocklive.de/corleone/teampanel/getPermissions', {
            headers: {
                'Authorization': token
            }
        })

        return response.data;

    } catch (error) {
        return "Error";
    }
}

export default getPermissions;