// react-router-dom components
import { useState, useEffect } from 'react'

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/login/components/CoverLayout";

// Images
import curved from "assets/images/curved-images/curved14.jpg";

// Supabase
import { useNavigate } from "react-router-dom/dist";
import { supabase } from '../../config/supabase-client';

function SignIn() {
  const [session, setSession] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getSession().then(async (value) => {
        if (value.data?.session && value.data?.session?.user) {
          setSession(value.data?.session);
        }
      });
    }
    getUserData()

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  const Login = async () => {
    const { data, error } = supabase.auth.signInWithOAuth({
      provider: "discord",
      options: {
        redirectTo: 'https://teampanel.corleone.vip/login'
        // redirectTo: 'http://teampanel.corleone.vip:3000/login'
      }
    });
    if (error) throw error
  }

  if (!session) {
    return (
      <CoverLayout
        title="Willkommen zurück"
        description="Auf dem offiziellen Team-Panel von Corleone City."
        image={curved}
      >
        <SoftBox component="form" role="form">
          <SoftBox mt={4} mb={1}>
            <SoftButton variant="gradient" color="info" onClick={Login} fullWidth>
              mit discord anmelden
            </SoftButton>
          </SoftBox>
          <SoftBox mt={3} textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              Du wirst nicht weitergeleitet? Wende dich an CC | Paul{" "}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    );
  } else {
    navigate("/dashboard", { replace: true });
  }
}

export default SignIn;
