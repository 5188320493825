import React, { useState, useEffect, Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import routes from "routes";
import { useSoftUIController, setMiniSidenav } from "context";
import brand from "assets/images/logo-ct.png";
import SignIn from "layouts/login";
import SignOut from "layouts/logout";
import { supabase } from "./config/supabase-client";
import getPermissions from "./config/getPermissions";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [realRoutes, setRealRoutes] = useState([]);
  const [memberView, setMemberView] = useState(null)

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes, userPermissions) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse, userPermissions);
      }
      if (route.route && hasPermission(userPermissions, route.permission)) {
        const Component = lazy(() => import(`./${route.componentPath}`));
        return (
          <Route
            exact
            path={route.route}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Component />
              </Suspense>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  useEffect(() => {
    async function getUserData() {
      const { data: sessionData } = await supabase.auth.getSession();
      if (sessionData?.session && sessionData.session.user) {
        const permissionResponse = await getPermissions(sessionData.session.refresh_token);
        if (permissionResponse) {
          setPermissions(permissionResponse);
          setRealRoutes(getRoutes(routes, permissionResponse));
          const MemberStats = lazy(() => import("layouts/teamleitung/member-stats"));
          setMemberView(
            <Route
              path={"/teamleitung/member-stats/:id"}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <MemberStats />
                </Suspense>
              }
              key={"memberstats"}
            />
          );
        } else {
          setPermissions({
            pages: {
              dashboard: false,
              teamleitung: false,
            },
            roles: [],
          });
        }
      }
      setLoading(false);
    }
    getUserData();
  }, []);

  function hasPermission(userPermissions, requiredPermission) {
    const { pages = {}, roles = [] } = userPermissions;

    if (pages[requiredPermission]) {
      return true;
    }

    const requiredParts = requiredPermission.split("/");
    for (const part of requiredParts) {
      if (roles.includes(part) || pages[part]) {
        return true;
      }
    }
    return false;
  }

  if (loading) {
    document.body.style.margin = "0";
    document.body.style.padding = "0";

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#191A1C",
          color: "white",
          fontSize: "14px",
        }}
      >
        <div>
          <div style={{ marginTop: "20px" }}>Loading session...</div>
        </div>
      </div>
    );
  }

  const MemberStats = hasPermission(permissions, "teamleitung/863492133150392364")
    ? lazy(() => import("layouts/teamleitung/member-stats"))
    : null;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Corleone City | Team"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      <Routes>
        {memberView}
        <Route path={"/login"} element={<SignIn />} key={"login"} />
        <Route path={"/logout"} element={<SignOut />} key={"logout"} />
        <Route path="*" element={<Navigate to="/login" />} />
        {realRoutes}
      </Routes>
    </ThemeProvider>
  );
}