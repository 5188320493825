// superbase components
import { useNavigate } from "react-router-dom/dist";
import React, { useEffect, useState } from "react";
import { supabase } from "../../config/supabase-client";

function Logout() {
  const navigate = useNavigate();
  async function signOut() {
    await supabase.auth.signOut();
    navigate("/login", { replace: true });
  }

  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getSession().then(async (value) => {
        if (value.data?.session && value.data?.session?.user) {
          setSession(value.data?.session);

          setLoading(false);
        }
      });
    }
    getUserData();
  }, []);
  if (session && !loading) {
    return signOut();
  } else {
    return (<div>Du bist nicht angemeldet.</div>)
  }
}

export default Logout;
